.modal-select-company {
  @media (min-width: 1024px) {
    min-width: 730px;
  }
  .modal-header {
    padding-bottom: 5px;
    border-bottom: 0;
    .modal-title {
      text-transform: capitalize;
      font-weight: 700;
      font-size: 24px;
      letter-spacing: 0.6px;
    }
  }
  .react-select-company {
    input {
      width: 100%;
      /* height: 100%; */
      /* display: flex; */
      border: 0 !important;
      padding: 10px;
      font-size: 24px;
      box-shadow: none !important;
      ::placeholder {
        color: var(--gf-neutral-300);
      }
      &:focus,
      &:focus-visible,
      &:hover {
        box-shadow: none;
        border: 0;
        outline: none;
      }
    }
    [class$='-control'] {
      border-color: var(--gf-primary-300);
      box-shadow: none;
      min-height: 80px;
      cursor: pointer;
      &:hover {
        border-color: var(--gf-primary-300);
      }
    }
    [class$='-option'] {
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: white;
      &:hover,
      &:focus {
        background-color: var(--gf-neutral-100);
      }
      &:active {
        background-color: var(--gf-neutral-100);
      }
      .country-flag {
        bottom: 7px;
      }
    }
    .dropdown-indicator {
      background-color: var(--gf-primary-50);
      margin-right: 20px;
      border-radius: 4px;
      height: 30px;
      width: 30px;
      text-align: center;
      display: flex;
      margin-top: 0;
      justify-content: center;
      align-items: center;
      svg {
        color: var(--gf-primary-300);
      }
    }
    [class$='-indicatorSeparator'] {
      background-color: transparent;
    }
    .logo {
      border: 3px solid var(--gf-neutral-200, #ececed);
      border-radius: 60px;
      height: 60px;
      margin-right: 10px;
      object-fit: cover;
      padding: 0;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--gf-neutral-300);
      color: white;
      text-transform: uppercase;
    }
    .country-flag {
      // border-radius: 100%;
      bottom: 5px;
      // overflow: hidden;
      position: absolute;
      left: 55px;
      // width: 24px;
      img {
        box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.08);
        border-radius: 100%;
        width: 24px;
        height: 24px;
        border: 0;
        object-fit: cover;
      }
    }
  }
  .company-information {
    margin-left: 15px;
    .name {
      display: flex;
      font-size: 18px;
      font-weight: 700;
      color: var(--gf-primary-300);
      text-transform: uppercase;
      .verified-status {
        margin-left: 8px;
        margin-top: -2px;
      }
    }
  }
  .modal-footer {
    justify-content: center;
  }
  .footer {
    margin-top: 40px;
    margin-bottom: 25px;
    button {
      min-width: 10rem;
    }
  }
}
