.select-corporate-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url(https://static-content.giftano.com/uploads/giftano_account_0d02b3fe17/giftano_account_0d02b3fe17.png);
  background-size: cover;
  .card {
    max-width: 500px;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 30px;
    &-title {
      font-size: 1.5rem !important;
      font-weight: 700 !important;
    }
    &-body {
      padding-top: 0;
    }
  }
  .corporate-name {
    color: var(--gf-primary-300);
  }
  .corporate-item {
    border: none;
    border-bottom: 1px solid var(--gf-neutral-200);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 0;
    &:last-child {
      border-bottom: 0;
    }
    .country-flag {
      width: 24px;
      position: absolute;
      right: 5px;
      bottom: 0;
      box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.08);
      border-radius: 100%;
      overflow: hidden;
      img {
        margin: 0 auto;
        width: 100%;
        height: auto;
      }
    }
    .pointer-icon {
      background-color: var(--gf-primary-50);
      width: 30px;
      height: 30px;
      padding: 5px;
      text-align: center;
      border-radius: 4px;
    }

    .corporate-image-container {
      position: relative;
      margin-right: 20px;
    }
    .logo {
      border: 3px solid var(--gf-neutral-200, #ececed);
      border-radius: 60px;
      width: 60px;
      height: 60px;
      margin-right: 10px;
      padding: 0;
      object-fit: cover;
    }
    p.logo {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: 700;
      background-color: var(--gf-neutral-300);
      color: white;
    }
    .corporate-information {
      font-weight: 700;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      .label {
        min-width: 100px;
        font-size: 1rem;
        font-weight: 400;
      }
      .information {
        font-weight: 700;
        font-size: 1rem;
        text-transform: uppercase;
      }
    }
    h4 {
      text-transform: capitalize;
    }
    p {
      margin: 0px;
      color: var(--gf-neutral-400);
    }
  }
}
