.create-account-page {
  .section-divider {
    padding: 10px;
    background-color: var(--gf-primary-100);
    border-radius: var(--gf-border-radius-m);
  }
  .form-label {
    text-transform: capitalize;
  }
  margin-top: -1em;
  header {
    position: relative;
    margin-bottom: 2em;
    border-bottom: 2px solid var(--gf-primary-200);
    &::after {
      width: 100%;
      position: absolute;
      // content: '';
      background-color: #f5f8fa;
      height: 1px;
      z-index: -1;
      box-shadow: 0 4px 20px rgba(125, 125, 125, 0.1);
      top: 0;
    }
    img {
      max-width: 100%;
      height: 40px;
    }
  }
  .react-tel-input {
    .form-control {
      width: 100%;
      border: 1px solid transparent;
      background-color: var(--gf-neutral-100);
      color: var(--gf-neutral-400);
      font-size: 16px;
      font-weight: 400;
      height: 42px;
      border-radius: 0.475rem;
      // margin-bottom: 1em !important;
      &::placeholder {
        color: var(--gf-neutral-300);
      }

      // Firefox
      &::-moz-placeholder {
        color: var(--gf-neutral-300);
        opacity: 1;
      }
    }
    .flag-dropdown {
      border-radius: 6.175px 0 0 6.175px !important;
      border: 1px solid transparent;
    }
  }
}

.create-account {
  .login-block {
    position: relative;
    display: flex;
    justify-content: center;
    justify-items: center;
    max-height: 100vh;
    min-height: 100px;
    height: 100%;
  }
  .login-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .business-content {
    height: 100%;
  }
  .bg-account-container {
    position: relative;
    height: 0;
    // padding-top: 56.25%;
    padding-top: 100%;
    overflow: hidden;
    background-color: var(--gf-light-gray-200);
  }

  .bg-account {
    width: 100%;
    position: absolute;
    // height: 100vh;
    background-size: contain;
    background-image: url(https://giftano.imgix.net/assets/giftano-business-register.jpg?auto=format);
    background-repeat: no-repeat;
    top: 0;
    // left: 0;
    // background-color: var(--gf-light-gray-200);
    bottom: 0;
  }
  // .bg-account {
  //   background-size: contain;
  //   background-image: url('https://giftano.imgix.net/assets/Giftano-for-business-login-1x.jpg?auto=format');
  //   height: 100vh;
  //   background-repeat: no-repeat;
  //   width: 50%;
  //   max-width: 100%;
  // }
  &.layoutWrapper {
    height: 100vh;
    overflow: hidden;
  }
  .auth-logo {
    width: 100%;
    height: 40px;

    object-fit: contain;
  }
  .wrapper-login {
    min-height: 100px;
    margin: auto;
    background-color: #ffffff;
    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      color: black;
      margin-top: 40px;
      margin-bottom: 25px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: black;
    }
    .btn {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

@media screen and (min-width: 1024px) {
  .create-account {
    .card-body {
      width: 82%;
      margin: 0 auto;
    }
  }
  .create-account-page {
    .form-shady {
      width: 80%;
      margin: 0 auto;
    }
  }
}

@media (max-width: 1023px) {
  // .login-block {
  //   bottom: 0;
  //   left: 0;
  //   margin: 10vh auto auto;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  // }
  .create-account > div {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    // height: inherit !important;
  }
  .create-account.layoutWrapper {
    overflow: scroll;
    background-color: var(--gf-light-gray-300);
    height: 100%;
    overflow-y: hidden;
  }
  .create-account .login-block {
    display: block;
    position: relative;
    max-height: 100%;
    overflow: scroll;
    min-height: auto;
    height: auto;
  }
  .login-wrapper {
    background-color: var(--gf-light-gray-300) !important;
  }
  .create-account .bg-account {
    width: 100%;
    height: 100vh;
    min-height: 100%;
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    background-size: contain;
    background-color: transparent;
    margin-top: 35%;
  }
  .create-account .bg-account-container {
    height: max-content;
    overflow: hidden;
    width: 100%;
    padding-top: 0;
    background-color: var(--gf-light-gray-300);
  }
  .create-account .wrapper-login {
    background-color: var(--gf-light-gray-300);
    & > .card {
      background-color: var(--gf-light-gray-300);
    }
  }
  .cta-mobile {
    text-align: center;
    margin-top: -150px;
    & > * {
      width: 100%;
      // margin-bottom: 2em;
    }
    .btn {
      margin-top: 1em;
    }
  }
}

@media screen and (max-width: 767px) {
  .login-block {
    bottom: 0;
    left: 0;
    // margin: 10vh auto auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    padding: 0;
  }
  .cta-mobile {
    margin-top: 7em;
    margin-bottom: 3em;
    z-index: 110;
    position: absolute;
    top: -8em;
    left: 0;
    right: 0;
  }
}
