//
// Pagination
//

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  // justify-content: center;
  margin: 0;

  // Pagination circle
  &.pagination-circle {
    .page-link {
      border-radius: 50%;
    }
  }

  // Pagination outline
  &.pagination-outline {
    .page-link {
      border: 1px solid $border-color;
    }

    .page-item {
      &:hover,
      &.active {
        .page-link {
          border-color: $primary-light;
        }
      }
    }
  }
}

.page-item {
  margin-right: $pagination-item-space;

  &:last-child {
    margin-right: 0;
  }

  .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $btn-border-radius;
    height: $pagination-item-height;
    min-width: $pagination-item-height;
    font-weight: $pagination-font-weight;
    font-size: $pagination-font-size;

    i {
      font-size: $pagination-icon-font-size;
    }

    /*rtl:options:{"autoRename":false}*/
    .previous,
    .next {
      display: block;
      height: $pagination-icon-height;
      width: $pagination-icon-height;

      /*rtl:raw:transform: rotateZ(-180deg);*/
    }
    /*rtl:end:ignore*/

    .previous {
      @include svg-bg-icon(arrow-start, $pagination-color);
    }

    /*rtl:options:{"autoRename":false}*/
    .next {
      @include svg-bg-icon(arrow-end, $pagination-color);
    }
  }

  /*rtl:options:{"autoRename":false}*/
  &.next,
  &.previous {
    .page-link {
      background-color: $pagination-item-bg;

      i {
        color: $pagination-color;
      }

      .svg-icon {
        @include svg-icon-color($pagination-color);
      }
    }
  }

  &:focus {
    .page-link {
      i {
        color: $pagination-focus-color;
      }

      .svg-icon {
        @include svg-icon-color($pagination-focus-color);
      }

      .previous {
        @include svg-bg-icon(arrow-start, $pagination-focus-color);
      }

      /*rtl:options:{"autoRename":false}*/
      .next {
        @include svg-bg-icon(arrow-end, $pagination-focus-color);
      }
    }
  }

  &:hover {
    .page-link {
      i {
        color: $pagination-hover-color;
      }

      .svg-icon {
        @include svg-icon-color($pagination-hover-color);
      }

      .previous {
        @include svg-bg-icon(arrow-start, $pagination-hover-color);
      }

      /*rtl:options:{"autoRename":false}*/
      .next {
        @include svg-bg-icon(arrow-end, $pagination-hover-color);
      }
    }
  }

  &.active {
    .page-link {
      i {
        color: $pagination-active-color;
      }

      .svg-icon {
        @include svg-icon-color($pagination-active-color);
      }

      .previous {
        @include svg-bg-icon(arrow-start, $pagination-active-color);
      }

      /*rtl:options:{"autoRename":false}*/
      .next {
        @include svg-bg-icon(arrow-end, $pagination-active-color);
      }
    }
  }

  &.disabled {
    .page-link {
      i {
        color: $pagination-disabled-color;
      }

      .svg-icon {
        @include svg-icon-color($pagination-disabled-color);
      }

      .previous {
        @include svg-bg-icon(arrow-start, $pagination-disabled-color);
      }

      /*rtl:options:{"autoRename":false}*/
      .next {
        @include svg-bg-icon(arrow-end, $pagination-disabled-color);
      }
    }
  }
  /*rtl:end:ignore*/
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .page-item {
    margin-right: $pagination-item-space-tablet-and-mobile;

    &:last-child {
      margin-right: 0;
    }
  }
}
