:root {
  --gf-neutral-50: #fff;
  --gf-neutral-100: #f9f9f9;
  --gf-neutral-200: #ececed;
  --gf-neutral-300: #c4c4c4;
  --gf-neutral-400: #5c5c5c;
  --gf-neutral-500: #000;

  --gf-primary-50: #e6f4f2;
  --gf-primary-100: #9ad1cc;
  --gf-primary-200: #4eafa6;
  --gf-primary-300: #028c80;
  --gf-primary-400: #027066;
  --gf-primary-500: #01544d;

  --gf-error-50: #fef3f2;
  --gf-error-100: #fee4e2;
  --gf-error-200: #fda29b;
  --gf-error-300: #f04438;
  --gf-error-400: #b42318;
  --gf-error-500: #7a271a;

  --gf-warning-50: #fef4e6;
  --gf-warning-100: #fcd39d;
  --gf-warning-200: #f9b153;
  --gf-warning-300: #f79009;
  --gf-warning-400: #c67307;
  --gf-warning-500: #633a04;

  --gf-success-50: #e7f8f0;
  --gf-success-100: #a0e2c3;
  --gf-success-200: #59cd97;
  --gf-success-300: #12b76a;
  --gf-success-400: #0d804a;
  --gf-success-500: #053720;

  --gf-dark-violet-50: #eae9eb;
  --gf-dark-violet-100: #827c89;
  --gf-dark-violet-200: #443a4e;
  --gf-dark-violet-300: #2f243a;
  --gf-dark-violet-400: #2a2034;
  --gf-dark-violet-500: #211929;

  --gf-soft-orange-50: #fff5ed;
  --gf-soft-orange-100: #fed8b9;
  --gf-soft-orange-200: #fdbb84;
  --gf-soft-orange-300: #fc9e4f;
  --gf-soft-orange-400: #ca7e3f;
  --gf-soft-orange-500: #975f2f;

  --gf-light-gray-50: #fbfefe;
  --gf-light-gray-100: #f0faf9;
  --gf-light-gray-200: #e5f7f5;
  --gf-light-gray-300: #daf3f1;
  --gf-light-gray-400: #aec2c1;
  --gf-light-gray-500: #839291;

  --gf-light-gray-orange-50: #fdfbf9;
  --gf-light-gray-orange-100: #f9f0e7;
  --gf-light-gray-orange-200: #f4e5d5;
  --gf-light-gray-orange-300: #efdac3;
  --gf-light-gray-orange-400: #bfae9c;
  --gf-light-gray-orange-500: #8f8375;

  --gf-dark-red-50: #f7eae6;
  --gf-dark-red-100: #dfaa9a;
  --gf-dark-red-200: #c76a4e;
  --gf-dark-red-300: #af2a02;
  --gf-dark-red-400: #8c2202;
  --gf-dark-red-500: #7a1d01;

  --gf-violet-50: #eee6f7;
  --gf-violet-100: #b99adf;
  --gf-violet-200: #854ec7;
  --gf-violet-300: #5002af;
  --gf-violet-400: #40028c;
  --gf-violet-500: #300169;

  --gf-primary-color: var(--gf-primary-300);
  --gf-error-color: var(--gf-error-300);
  --gf-warning-color: var(--gf-warning-300);
  --gf-neutral-color: var(--gf-neutral-300);
  --gf-success-color: var(--gf-success-300);

  /**
  * @tokens Easings
  * @presenter Easing
  */

  --gf-easing-swift: cubic-bezier(0.55, 0, 0.1, 1);

  /**
  * @tokens Font Families
  * @presenter FontFamily
  */
  --base-font-family: 'Lato', sans-serif;
  --mono-font-family: Monaco, monospace;
  --web-font-family: 'Lato';

  /**
  * @tokens Font Sizes
  * @presenter FontSize
  */

  --gf-fontsize-12: 12px;
  --gf-fontsize-14: 14px;
  --gf-fontsize-16: 16px;
  --gf-fontsize-18: 18px;
  --gf-fontsize-20: 20px;
  --gf-fontsize-23: 23px;
  --gf-fontsize-26: 26px;
  --gf-fontsize-29: 29px;
  --gf-fontsize-32: 32px;
  --gf-fontsize-36: 36px;
  --gf-fontsize-41: 41px;
  --gf-fontsize-46: 46px;

  /**
  * @tokens Font Weights
  * @presenter FontWeight
  */

  --gf-font-weight-regular: 400;
  --gf-font-weight-semibold: 500;
  --gf-font-weight-bold: 600;
  --gf-font-weight-extrabold: 800;

  /**
  * @tokens Line Heights
  * @presenter LineHeight
  */

  --gf-line-height: 1.5;

  /**
  * @tokens Letter Spacings
  * @presenter LetterSpacing
  */

  --gf-letter-spacing-none: 0;
  --gf-letter-spacing-s: 1px;
  --gf-letter-spacing-m: 2px;

  /**
  * @tokens Opacities
  * @presenter Opacity
  */

  --gf-opacity: 0.5;

  /**
  * @tokens Animations
  * @presenter Animation
  */

  --gf-animation-rotate: rotate 1.2s infinite cubic-bezier(0.55, 0, 0.1, 1);

  /**
  * @tokens Border Radius
  * @presenter BorderRadius
  */

  --gf-border-radius-s: 2px;
  --gf-border-radius-m: 4px;
  --gf-border-radius-l: 30px;

  /**
  * @tokens Borders
  * @presenter Border
  */

  --gf-border-normal: 1px solid var(--neutral-300);
  --gf-border-active: 1px solid var(--neutral-400);

  /**
  * @tokens Shadows
  * @presenter Shadow
  */

  --gf-shadow-thin: 0 4px 20px rgba(125, 125, 125, 0.1);
  --gf-shadow-normal: 0 10px 20px -10px rgba(0, 0, 0, 0.25);
  --gf-shadow-thick: 0 4px 20px rgba(125, 125, 125, 0.25);

  /**
  * @tokens Spacings
  * @presenter Spacing
  */

  --gf-spacing-s: 8px;
  --gf-spacing-m: 12px;
  --gf-spacing-l: 16px;

  /**
  * @tokens Z-Index
  */

  --gf-z-index: 1000;
}

$gf-neutral-50: #fff;
$gf-neutral-100: #f9f9f9;
$gf-neutral-200: #ececed;
$gf-neutral-300: #c4c4c4;
$gf-neutral-400: #5c5c5c;
$gf-neutral-500: #000;

$gf-primary-50: #e6f7f6;
$gf-primary-100: #9adfd9;
$gf-primary-200: #4ec7bd;
$gf-primary-300: #02afa0;
$gf-primary-400: #028d81;
$gf-primary-500: #014a44;
$gf-error-50: #fef3f2;
$gf-error-100: #fee4e2;
$gf-error-200: #fda29b;
$gf-error-300: #f04438;
$gf-error-400: #b42318;
$gf-error-500: #7a271a;
$gf-warning-50: #fffaeb;
$gf-warning-100: #fef0c7;
$gf-warning-200: #fec84b;
$gf-warning-300: #f79009;
$gf-warning-400: #b54708;
$gf-warning-500: #7a2e0e;
$gf-success-50: #ecfdf3;
$gf-success-100: #d1fadf;
$gf-success-200: #6ce9a6;
$gf-success-300: #12b76a;
$gf-success-400: #027a48;
$gf-success-500: #054f31;
