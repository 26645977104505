.jumbotron {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 48px;
  padding-top: 48px;
  // border: 0.5px solid rgb(178, 178, 178);
  //   background-color: rgb(245, 245, 245);
  background-image: url('../../../../public/media/bg/welcome-backgrounb.jpg') !important;
  background-size: cover;
  border-radius: 5px;
  margin-bottom: 40px;

  &-title {
    font-size: 41px;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  p {
    font-size: 12pt;
  }
}

.promotion-img {
  position: relative;
  cursor: pointer;
  .promotion-content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
  }
}

.stream-player {
  border-radius: 12px;
  overflow: hidden;
  // height: 100%;
  iframe {
    border-radius: 4px !important;
  }
}

.hero {
  &-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  &-links {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    color: var(--gf-dark-violet-300);
  }
}
@keyframes skeleton-anim {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

.giblog {
  border-radius: var(--gf-border-radius-m);
  padding: 0px 0;
  // border: 1px solid rgb(178, 178, 178) !important;
  box-shadow: var(--gf-shadow-thick);
  overflow: hidden;

  &-image {
    &.loading {
      background-color: rgba(0, 0, 0, 0.2);
      animation: skeleton-anim 1s 0.5s infinite alternate;
      width: 100%;
      height: 150px;
      img {
        display: none;
      }
    }
    img {
      max-width: 100%;
      height: 150px;
      object-fit: cover;
    }
  }
  &-des {
    padding: 10px;
    h4:empty {
      background-color: rgba(0, 0, 0, 0.2);
      animation: skeleton-anim 1s 0.5s infinite alternate;
      height: 25px;
    }
    h4 {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      height: 60px;
      -webkit-box-orient: vertical;
      margin-bottom: 10px;
    }
    p:empty {
      background-color: rgba(0, 0, 0, 0.2);
      animation: skeleton-anim 1s 0.5s infinite alternate;
      height: 25px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--gf-neutral-400);
      -webkit-line-clamp: 4;
      line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      height: 80px;
      -webkit-box-orient: vertical;
      margin-bottom: 30px;
    }
  }
}

// .border {
//   border: 1px solid #000 !important;
// }
