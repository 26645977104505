//
// Toast
//

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}

/** Used to position the icon **/
.Toastify__toast-icon {
  color: var(--gf-neutral-50) !important;
}
.Toastify__toast-icon svg {
  fill: var(--gf-neutral-50) !important;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: var(--gf-primary-300) !important;
  color: var(--gf-neutral-50) !important;
}
.Toastify__toast--success {
  background: var(--gf-primary-300) !important;
  color: var(--gf-neutral-50) !important;
}
.Toastify__toast--warning {
  background: var(--gf-warning-300) !important;
  color: var(--gf-neutral-50) !important;
}
.Toastify__toast--error {
  background: var(--gf-error-300) !important;
  color: var(--gf-neutral-50) !important;
}
.Toastify__toast-body {
  margin: auto 0;
  padding: 6px;
}

.Toastify {
  &__progress-bar {
    &--info {
      background: var(--gf-neutral-50) !important;
    }
    &--success {
      background: var(--gf-neutral-50) !important;
    }
    &--warning {
      background: var(--gf-neutral-50) !important;
    }
    &--error {
      background: var(--gf-neutral-50) !important;
    }
  }
}

.Toastify__close-button {
  color: var(--gf-neutral-50) !important;
}
.Toastify__close-button--default {
  color: var(--gf-neutral-50) !important;
}
.Toastify__close-button > svg {
  color: var(--gf-neutral-50) !important;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}
