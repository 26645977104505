//
// Menu
//

// Aside menu

.aside-dark
  .menu
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled):not(.active):not(.here)
  .menu-icon
  i,
.aside-dark
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-icon
  i {
  color: white !important;
  border-radius: var(--gf-border-radius-m);
}

.aside-menu {
  .menu {
    // Link padding x
    @include menu-link-padding-x(get($aside-config, padding-x));
    // @include menu-link-padding-y(get($aside-config, padding-y));

    // Menu indention
    @include menu-link-indention(get($aside-config, menu-indention), get($aside-config, padding-x));
  }

  // accordion
  .menu-column {
    padding: 0 10px 60px 10px;
  }
  // Item
  .menu-item {
    padding: 5px 0;

    // Menu Link
    .menu-link {
      padding-top: 10px;
      // padding-top: 0.75rem;
      padding-bottom: 10px;
      // padding-bottom: 0.75rem;
      align-items: start !important;

      &.active .menu-icon i {
        color: white !important;
      }
      &.active,
      &:hover {
        border-radius: 8px;
      }

      .menu-bullet {
        align-self: center;
      }
      &.active {
        .menu-bullet {
          .bullet-dot {
            background-color: #f79009 !important;
          }
        }
      }

      .menu-title {
        font-size: 14px;
        font-weight: 600;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: start;
        .menu-subtitle {
          font-size: 12px;
        }
      }
    }

    // Icon
    .menu-icon {
      justify-content: flex-start;
      margin-top: 4px;
    }
  }
}

// Aside dark theme
.aside-dark {
  .hover-scroll-overlay-y {
    --scrollbar-space: 0.4rem;

    @include scrollbar-color(
      get($aside-config, scrollbar-color),
      get($aside-config, scrollbar-hover-color)
    );
  }

  .menu {
    .menu-item {
      &.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here),
      &:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
        border-radius: 8px;
      }
      .menu-link {
        .menu-icon {
          i {
            color: white !important;
          }
        }
      }

      .menu-section {
        color: #4c4e6f !important;
      }

      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state($white, $white, $white, $white, null);
      @include menu-link-hover-state($white, $primary, $white, $white, #211929);
      @include menu-link-here-state($white, $primary, $white, $white, transparent);
      @include menu-link-show-state($white, $primary, $white, $white, transparent);
      @include menu-link-active-state($white, $primary, $white, $white, transparent);

      &.hover {
        &.show {
          .menu-link {
            background-color: transparent;
            &:hover {
              background-color: #211929 !important;
              border-radius: var(--gf-border-radius-m);
            }
          }
          .menu-sub {
            .menu-item {
              &:hover {
                background-color: #211929 !important;
                border-radius: var(--gf-border-radius-m);
              }
            }
          }
        }
      }
    }
  }
}

.aside-dark .menu .menu-item.here {
  // background-color: var(--gf-dark-violet-500, #211929);
  border-radius: 4px;
}

.aside-dark .menu .menu-item .menu-link.active {
  // background-color: #827c89;
  // border-left: 5px solid #fc9e4f;
  color: #f79009;
  .menu-title {
    color: #f79009;
  }
}
// .aside-dark .menu-sub-accordion .menu-item .menu-link.active {
//   background-color: rgba(252, 158, 79, 30%) !important;
// }

.custom-tool-mobile {
  width: 100%;
  position: absolute;
  top: 50px;
  background-color: white;
  padding-bottom: 10px;
  left: 0;
  .company-name {
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 100%;
    width: auto;
    padding: 10px;
    margin-left: 0;
    color: var(--gf-neutral-400);
    cursor: pointer;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 700;
    .icon {
      height: 60%;
      width: 38px;
      padding: 5px 10px;
    }
  }
}

.menu-active-bg .menu-link.active {
  // background-color: transparent !important;
  // border-bottom: 1px solid #827c89;
  border-radius: 8px !important;
}

// Aside light theme
.aside-light {
  background-color: var(--gf-dark-violet-300) !important;
  .menu {
    .menu-title {
      font-weight: 500;
      color: $gray-100;
    }
  }
}

.mobile-input span {
  display: inline-block;
}

@media (min-width: 1024px) {
  .my-custom-toolbar {
    display: flex !important;
    justify-content: space-between;
  }
}

@media (max-width: 1023px) {
  .custom-tool-mobile {
    display: flex !important;
    .account-balance-wrapper {
      min-width: 140px;
      padding-right: 15px;
    }
    .account-fund-label {
      display: none;
    }
  }
  [title='Show header menu'] {
    display: none !important;
  }
  .mobile-input span:first-child {
    min-width: 120px;
    margin-right: 0 !important;
  }

  .account-balance {
    border: 1px solid var(--gf-neutral-300);
    padding: 5px 10px;
    border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .custom-tool-mobile > div {
    float: left;
  }
}
// @media (max-width: 767px) {
//   #kt_content {
//     padding-top: 70px;
//   }
// }
