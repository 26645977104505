.card-catalog {
  // border: 1px solid #d4d4d4;
  background-color: #fff !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 300px;
  overflow: hidden;

  border-radius: 8px;
  border: 1px solid #ececed;
  padding: 20px;

  // .not-found {
  //   height: 200px;
  // }
  &:hover {
    box-shadow: 0px 4px 20px 0px rgba(154, 209, 204, 0.4);
  }
  .btn-detailed-catalog {
    padding: 0;
    border: none;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: calc(100% - 7px);
      width: 100%;
      top: 0;
      left: 0;
    }
    &:hover {
      &:after {
        background-repeat: no-repeat;
        background-position: center;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 576 512' height='2em' width='2em'%3E%3Cpath d='M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z'%3E%3C/path%3E%3C/svg%3E");
        background-color: rgba(0, 0, 0, 0.5);

        color: white;
        font-size: 40px;
        line-height: 12rem;
        @media (max-width: 1023px) {
          line-height: 2.4em;
        }
      }
    }
  }

  .product-image-hovered {
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .lazyload-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    .image-w-desc {
      display: flex;
      gap: 20px;
      div:has(> .catalog-image) {
        width: 160px;
        max-width: 160px;
      }
      .catalog-image {
        width: 160px;
        border-radius: 6px;
      }
      .box-short-desc {
        width: 100%;
        .catalog-merchant {
          font-size: 14px;
          color: #ca7e3f;
        }
        .sku-price {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .product-sku {
            font-size: 14px;
            margin-bottom: 0px !important;
          }
          .product-price {
            color: #f79009;
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
    }
    .cataolog-body {
      margin-top: 20px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      clear: both;

      .valid-month {
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        color: #028c80;
      }

      .box-desc-gift {
        font-size: 14px;
        line-height: 125%;
        margin-bottom: 20px;
      }

      .labeling {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        margin-top: auto;

        .label {
          padding: 5px;
          font-weight: 600;
          font-size: 12px;
          border-radius: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 50%;
          &-soft {
            background-color: #f7eae6;
          }
          &-dark {
            background-color: #9ad1cc;
          }
          &-medium {
            background-color: #eee6f7;
          }
        }
      }

      .btn {
        flex-shrink: 0;
        align-self: flex-end;
        border-radius: 70px !important;
      }
      .product-title {
        line-height: 2rem;
        letter-spacing: normal;
        font-size: 1.5em;
      }
    }
  }
}

.card-catalog-merchant {
  cursor: pointer;
  box-shadow: var(--gf-shadow-thick);
  // padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 75/47;
  img {
    border-radius: 4px;
  }
}

.catalog-container {
  .catalog-merchant-info {
    h4 {
      margin-bottom: 15px;
    }
    p {
      font-size: 14px;
      line-height: 1.45;
      color: #000;
    }
    .btn-link {
      background-color: transparent;
      border: none;
    }
  }
  .carousel-slider {
    border-radius: 10px;
  }
}

.imgborder {
  border-radius: 3px 3px 0 0;
  width: 100%;
  object-fit: cover;
}
.select-view-by {
  width: 150px;
  margin-left: 10px;
}
