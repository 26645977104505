// override metronic style here
// @import './mixin-custom';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
  font-family: 'Lato';
}

body {
  overflow: auto !important;
}

.btn {
  i {
    //change padding to margin for better when spin
    padding-right: 0;
    margin-right: 0.35rem;
  }
}

// ---

.menu-item-child {
  max-width: 300px;
  display: inline-block;
  white-space: pre;
  overflow: hidden;
  position: relative;
  z-index: 10;
  padding-right: 30px;
  /* -webkit-line-clamp: 3; */
  line-clamp: 3;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  display: -webkit-box !important;
}

.mobile-input {
  width: 100%;
}

.floating-bar {
  position: sticky;
  bottom: 20px;
  z-index: 20;
  &::before {
    content: '';
    position: absolute;
    background-color: white;
    width: calc(100% + 30px);
    height: calc(100% + 40px);
    z-index: -1;
    top: 0;
    left: -15px;
    right: 0;
    bottom: 0;
    margin: auto;
    box-shadow: 0 -10px 10px -10px rgba(82, 63, 105, 0.15);
  }
}

.page-title-custom {
  font-size: 18px;
  font-weight: 700;
  color: var(--gf-neutral-500);
  margin-top: 24px;
  margin-bottom: 24px;
}

// .react-tel-input {
//   .form-control,
//   .flag-dropdown {
//     border-color: #e4e6ef !important;
//   }
// }

.my-custom-toolbar {
  width: 100%;

  // & > div:first-child {
  //   width: 43%;
  // }
  .company-name {
    display: inline-flex;
    overflow: hidden;
    max-width: 100%;
    width: auto;
    position: relative;
    padding: 10px;
    margin-left: 0;
    color: var(--gf-neutral-400);
    cursor: pointer;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 700;
    border: 1px solid var(--gf-neutral-200);
    margin: 10px 0;
    width: 350px;
    .company-information {
      margin-left: 15px;
      .name {
        color: var(--gf-primary-300);
        text-transform: uppercase;
        display: inline-flex;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        /* padding-right: 3px; */
        font-weight: 700;
        max-width: 250px;
      }
    }
    .country-flag {
      border-radius: 100%;
      bottom: 10px;
      box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.08);
      overflow: hidden;
      position: absolute;
      left: 35px;
      width: 15px;
      img {
        width: 100%;
        height: auto;
        border: 0;
      }
    }
    .verified-status {
      // margin-left: 5px;
      // margin-top: -2px;
      height: 15px;
      width: 15px;
    }
    .caret-indicator {
      display: flex;
      align-items: center;
      margin-left: 3em;
      svg {
        width: 16px;
        height: 16px;
        background-color: var(--gf-primary-50);
        border-radius: 4px;
      }
    }
    .logo {
      // border: 3px solid var(--gf-neutral-200, #ececed);
      border: none;
      border-radius: 60px;
      height: 40px;
      margin-right: 10px;
      object-fit: cover;
      padding: 0;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--gf-neutral-300);
      color: white;
      text-transform: uppercase;
    }
    .icon {
      height: 32px;
      width: 32px;
      padding: 5px 10px;
    }
  }
  .verified-status {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 10px;
    height: 30px;
    cursor: pointer;
    background-color: var(--gf-neutral-100);
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }
  .account-balance-wrapper {
    // min-width: 270px;
    border: 1px solid var(--gf-neutral-200, #ececed);
    padding: 10px;
    margin-left: 12px;
    border-radius: 12px;
    min-width: 170px;
    position: relative;
    .caret-indicator {
      height: 30px;
      cursor: pointer;
      background-color: var(--gf-neutral-100);
      padding: 5px;
      display: flex;
      align-items: center;
      border-radius: 8px;
    }
  }
  .account-fund-label {
    color: #5c5c5c;
    font-size: 12px;
    font-weight: 400;
  }
  .account-balance {
    // background: var(--gf-neutral-100, #f9f9f9);
    padding: 2px 10px;
    // margin-left: 10px;
    // border: 1px solid var(--gf-neutral-200, #ececed);
    // border-radius: 12px;
    cursor: pointer;
    // color: var(--gf-neutral-400);
    font-weight: 700;
    font-size: 14px;
    display: flex;
    // justify-content: center;
    align-items: center;
  }
}
.contry-selector-wrapper {
  height: 50px;
  align-self: center;
  width: 80px;
  border: 1px solid #ececed;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
  /* background-color: #ececed; */
  // .selected-flag {
  //   transform: translateY(-10px);
  // }
}
.country-selector {
  background-color: var(--gf-neutral-200);
  border-radius: 6px;
  cursor: pointer;
  [class$='-control'] {
    border: none;
    cursor: pointer;
    &:hover {
      border-color: transparent;
      box-shadow: none;
    }
  }
  [class$='-option'] {
    display: flex;
    align-items: center;
    cursor: pointer;
    // padding: 8px 16px;
  }

  [role='listbox'] {
    min-width: 130px;
  }

  [class$='-menu'] {
    right: -14px;
    top: calc(100% + 10px);
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);
    min-width: 130px;
  }

  .selected-flag-label {
    line-height: 1;
  }

  input {
    display: none;
  }
}
#kt_header_user_menu_toggle .symbol .symbol-label {
  height: 50px;
  width: 50px;
  border-radius: 8px;
  border: 1px solid var(--gf-neutral-200);
}

.wrapper {
  background-color: #f5f5f5;
}
// #kt_toolbar {
//   background: #f5f5f5;
//   position: absolute;
// }

#kt_content_container {
  margin-top: 0 !important;
}
#kt_page_title {
  padding-left: 8px;
}

.toolbar {
  // visibility: hidden;
  display: none;
}

@media (min-width: 992px) {
  #kt_content {
    padding-top: 0;
  }
  .toolbar-fixed .toolbar {
    background: #f5f5f5;
    box-shadow: 0px 10px 30px 0px #f5f5f5;
  }
}

@import 'pages/MyProfilePage.scss';
@import 'reactSlider/ReactSlider.scss';
@import './pages/RegistrationPage.scss';
@import './pages/DashboardPage.scss';
@import './pages/CatalogPage.scss';
@import './pages/CreateAccountPage.scss';

.modal-title {
  font-size: 18px !important;
}

@media screen and (max-width: 1023px) {
  .menu-item {
    margin: 12px;
  }
  .mobile-input {
    margin-left: 12px;
  }

  .menu-item:first-child {
    margin: 0;
  }
  // .env-indicator {
  //   margin-top: 30px;
  // }
}

@media screen and (max-width: 767px) {
  .hero-content {
    width: 100%;
  }

  .jumbotron {
    padding-left: 30px;
    padding-right: 30px;
  }
  .jumbotron-title {
    font-size: 30px;
  }

  .jumbotron button {
    width: 100%;
    margin-top: 15px;
  }
}

// to remove text transform on modal
.title-no-transform {
  .modal-title {
    text-transform: none !important;
  }
}

.carousel {
  .slider-wrapper {
    .slider {
      .slide {
        z-index: 0 !important;
      }
    }
  }
}

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

.loading-text {
  display: flex;
  align-items: end;
  font-size: 20px;
  .dot {
    width: 6px;
    height: 6px;
    background-color: black;
    border-radius: 50%;
    margin-left: 5px;
    opacity: 0;
    animation: blink 1.5s infinite;
    margin-bottom: 6px;
  }

  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
