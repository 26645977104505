.dropdown-modified {
  //   &.btn-warning {
  //     background-color: var(--gf-warning-300);
  //     border-color: var(--gf-warning-300);
  //   }
  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }
  .dropdown-menu {
    &.show {
      width: 100%;
      top: 10px !important;
      padding-left: 6px;
      padding-right: 6px;
      background-color: var(--gf-dark-violet-200);
      //  border: 1px solid transparent;
      .dropdown-item {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 28px;
        border: 1px solid transparent;
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        .lb-subtitle {
          font-size: 12px;
          // font-weight: 400;
        }
        &:hover {
          color: #fc9e4f;
          text-decoration: none;
          background-color: var(--gf-dark-violet-300);
          border-radius: 4px;
        }
      }
    }
  }
}

// .aside-dark .menu .menu-item.here.menu-accordion {
//   background-color: transparent;
// }

.aside-dark .menu .menu-item .menu-link.active {
  color: #f79009;
  background-color: #211929;
  .menu-subtitle {
    color: #f9b153;
  }
}
