.horizontal-slider {
  width: 100%;
  max-width: 100%;
  margin: auto;
  height: 50px;
}

.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-0 {
  background: #83a9ff;
}

.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}

.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}
