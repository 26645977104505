//
// Form Control
//
.form {
  &-label {
    font-size: 16px;
    line-height: 40px;
    font-weight: 400;
    color: black;
    vertical-align: middle;
  }
}

.form-shady {
  &::placeholder {
    color: var(--gf-neutral-300);
  }

  // Firefox
  &::-moz-placeholder {
    color: var(--gf-neutral-300);
    opacity: 1;
  }
  input,
  select,
  textarea {
    color: var(--gf-neutral-400);
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 400;
    background-color: var(--gf-neutral-100);
  }
  .form-control {
    background-color: var(--gf-neutral-100);
    color: var(--gf-neutral-400);
  }
}
// Form control
.form-control {
  box-shadow: none !important;
  border-color: #c4c4c4 !important;

  // &.react-tel-input {
  //   border: 1px solid #c4c4c4 !important;
  // }

  // Dropdown shown state
  .dropdown.show > & {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
  }

  // Readonly state
  &[readonly] {
    background-color: $input-readonly-bg;
  }
  &[disabled] {
    background-color: $input-disabled-bg !important;
    color: $gray-400;
  }

  // Solid style
  &.form-control-solid {
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    @include placeholder($input-solid-placeholder-color);
    color: $input-solid-color;
    transition: $transition-input;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-solid-bg-focus;
      border-color: var(--gf-primary-300);
      color: $input-solid-color;
      transition: $transition-input;
    }
  }

  // Transparent style
  &.form-control-transparent {
    background-color: transparent;
    border-color: transparent;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: transparent;
      border-color: transparent;
    }
  }

  // Flush
  &.form-control-flush {
    @include input-reset();
  }
}

// Placeholder colors
.placeholder-gray-500 {
  @include placeholder($gray-500);
}

.placeholder-white {
  @include placeholder($white);
}

// Textarea reset resize
.resize-none {
  resize: none;
}

// react select

.react-select {
  &-container {
  }
  &_control {
  }
  &__value-container {
  }
  &__indicators {
  }
  &__menu {
  }
  &__menu-list {
  }
  &__option {
  }
}

.gf-select {
  background-color: var(--gf-neutral-300);
  border-radius: var(--gf-border-radius-m);
  [class*='-control'] {
    color: var(--gf-neutral-400);
    background-color: var(--gf-neutral-100);
    border: none;
    font-size: 16px;
    font-weight: 400;
    padding: 3px 5px;
  }
}
