.phone-register-b2b {
  .react-tel-input {
    input.form-control {
      //   border: none;
      height: 45.5px;
      border-color: #c4c4c4;
      border: 1px solid #c4c4c4 !important;
      background-color: var(--gf-neutral-100) !important;
      width: 100%;
    }
  }
}

.refister-b2b__control {
  height: 45.5px;
  background-color: var(--gf-neutral-100) !important;
  &.refister-b2b__control--is-focused {
    border-color: #c4c4c4 !important;
    border: 1px solid #c4c4c4 !important;
    box-shadow: none !important;
  }
  .refister-b2b__value-container {
    padding: 0rem 1rem;
    // font-weight: bold;
    letter-spacing: 1px;
    font-size: 16px;
  }
}
