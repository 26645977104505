@mixin font-14-light {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-14-bold {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-16-light {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.2 !important;
}
@mixin font-16-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-18-light {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-18-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-26-light {
  font-size: 26px;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-26-bold {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-24-light {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-24-bold {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-32-light {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-32-bold {
  font-size: 32px;
  font-weight: 900;
  line-height: 1.2;
}
@mixin font-41-bold {
  font-size: 41px;
  line-height: 1.2;
  font-weight: 700;
}
@mixin font-41-light {
  font-size: 41px;
  line-height: 1.2;
  font-weight: 400;
}

// utility
.font-14-bold {
  @include font-14-bold;
}
.font-14-light {
  @include font-14-light;
}
.font-16-bold {
  @include font-16-bold;
}
.font-16-light {
  @include font-16-light;
}
