.modal-input-inquiry {
  label {
    line-height: normal;
  }
  .modal-content {
    border-radius: 12px;
    overflow: hidden;
    .modal-body {
      background-color: #ececed;
      padding: 30px;
      .card-body {
        display: flex !important;
        gap: 20px;
        flex-direction: column;
        padding: 20px;
        .inquiry-note {
          padding: 20px;
          border-radius: 8px;
          background-color: #f9f9f9;
          border-color: transparent;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }
    .modal-footer {
      background-color: #ececed;
      border: none;
      padding-top: 0px;
    }
  }
  hr {
    border-color: var(--gf-neutral-300);
  }
  .form-label {
    margin-bottom: 0;
  }
  .form-label-country {
    transform: translate(48%, -37px);
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .topMessageInput.focused {
    border-color: #b5b5c3 !important;
  }
  // .messageInput:focus + .topMessageInput {
  //   border-color: #b5b5c3 !important;
  //   color: yellow !important;
  // }
  .box-input-group {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #ececed;
    // margin-bottom: 20px;
    background-color: #fff;
    .label-input {
      font-size: 16px;
      font-weight: 700;
      line-height: 125%;
      &-sm {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .box-input-multiple-recipient {
    display: flex;
    gap: 20px;
    // textarea {
    //   width: 500px;
    // }
    .box-help-input-multiple-recipient {
      font-size: 16px;
      font-weight: 400;
      line-height: 125%;
      .list-help-recipient {
        font-size: 14px;
        color: #909090;
        margin-top: 10px;
      }
    }
  }
  .box-input-delivery-date {
    display: flex;
    justify-content: center;
    gap: 16px;
    .label-input {
      margin-bottom: 10px;
      text-align: center;
    }

    .delivery-time {
      position: relative;
      width: 225px;
      .form-label-country {
        position: absolute;
        right: 70px;
        bottom: -25px;
      }
    }
  }
}

.gift-actions {
  // margin-left: 10px;
  min-width: 120px;
  @media (max-width: 400px) {
    margin: 0px;
    display: flex;
    justify-content: space-between;
  }
}

.qty-group-inquiry {
  max-width: 100%;
  margin-bottom: 0px !important;
  button {
    padding: 8px !important;
  }
  .form-control {
    padding: 4px;
  }
  .qty-group-inquiry {
    display: flex;
    width: 100% !important;
    input {
      border-radius: 0;
      height: 42px !important;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
    .btn-plus {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .btn-min {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.label-section {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px !important;
}

.section-inquiry-1 {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .form-group {
    flex: 1;
    margin-bottom: 0 !important;
    // border: 1px solid #000;
  }
}
.delivery-opt {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 0px !important;
}
.input-radio-inquiry {
  display: flex;
  gap: 10px;
  justify-items: center;
  align-items: center;
  label {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
  }
  .form-check-input {
    width: 1.3rem;
    height: 1.3rem;
  }
}

.fw-bold {
  font-weight: bold !important;
}
// rgba(255, 255, 255, 0.8)
.section-inquiry-2 {
  display: flex;
  // border: 1px solid;
  justify-content: space-between;
  gap: 10px;
  .col-item {
    flex: 1;
    min-height: 125px;
    border-radius: 10px;
    display: flex;
    align-items: end;
    padding: 5px;
    height: 160px;

    .item {
      flex: 1;
      display: flex;
      align-items: end;
      div {
        flex: 1;
        padding: 10px 10px;
        border-radius: 6px;
        display: flex;
        justify-items: center;
        align-items: center;
        gap: 10px;
        font-weight: bold;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.8);
        min-height: 54px;
        label {
          font-weight: bold !important;
          font-size: 14px;
        }
        input {
          border-color: #5c5c5c;
          margin: 1px;
          width: 20px;
          height: 20px;
          &:checked {
            border-color: #028c80;
          }
        }
      }
    }
  }
  .voucher {
    background-image: url('./unactivated-gift-cards.jpeg');
    background-size: cover;
    background-position: center;
  }
  .reward {
    background-image: url('./normal-gift-cards.jpeg');
    background-size: cover;
    background-position: center;
  }
}

.section-inquiry-3 {
  display: flex;
  justify-content: space-between;

  gap: 10px;
  .col-item {
    flex: 1;
    border: 1px solid #e4e6ef;
    border-radius: 10px;
    .header-col {
      border-bottom: 1px solid #e4e6ef;
      padding: 10px;

      label {
        height: 28px;
        display: flex;
        align-items: center;
        line-height: normal;
        font-size: 14px;
        font-weight: bold;
      }
      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .body-col {
      padding: 10px;

      // ul:first-child li:before {
      //   content: '\2713\0020';
      //   color: rgba(18, 183, 106, 1);
      //   font-size: 18px;
      // } /* OR */
      // ul:nth-child(2) li:before {
      //   content: '\2714\0020';
      //   color: rgba(18, 183, 106, 1);
      //   font-size: 18px;
      // } /* OR */
      // ul:last-child li:before {
      //   content: '\2611\0020';
      //   color: rgba(18, 183, 106, 1);
      //   font-size: 18px;
      // }
      ul {
        list-style-type: none;
        // padding: 0;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          display: flex;
          gap: 5px;
          svg {
            color: #12b76a;
            width: 16px;
            height: 16px;
            margin-top: 2px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.inquiry-accordion {
  .accordion-header {
    border: none;

    button {
      font-size: 12px;
      font-weight: bold;
      color: rgba(2, 140, 128, 1);
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 5px;
      padding-bottom: 5px !important;
      box-shadow: none;
      background-color: #fff;
    }
  }

  .accordion-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.frm-group-msg {
  margin-bottom: 0px !important;
}
.lable-key-feature {
  font-size: 14px;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 13.5px !important;
  margin-top: 13.5px !important;
  color: #028c80;
  text-align: center;
}
.action-button {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
