.custom-ui-confirm {
  .modal-content {
    border-radius: 12px;
    overflow: hidden;
  }
  .modal-body {
    padding: 30px;
    text-align: center;
  }
  .box-title-confirm {
    font-size: 23px;
    font-weight: 700;
    color: #028c80;
    margin-bottom: 20px;
  }
  .box-content-confirm {
    font-size: 18px;
    font-weight: 400;
    line-height: 125%;
  }
  .box-action-confirm {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 40px;
    button {
      border: 1px solid #ececed;
      width: 120px;
    }
  }
}
