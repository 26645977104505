.modal-topup {
  .modal-header {
    border-bottom: none;
    .title-n-back {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .quotation-topup {
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #9ad1cc;
    background-color: #e6f4f2;
    font-size: 14px;
    margin-bottom: 35px;
  }
  .frm-topup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .input-grp {
      margin-bottom: 0px !important;
      display: flex;
      flex-direction: column;
      gap: 10px;
      label {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0px;
        line-height: 125%;
      }
    }
  }
  .btn-next-payment {
    padding: 14px 20px;
    margin-top: 35px;
  }
  .card-balance {
    display: flex;
    gap: 20px;
    .frm-group {
      margin-bottom: 0px !important;
      width: 100%;
      label {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        line-height: 125%;
      }
    }
  }
  .topup-container {
    .summary-title {
      font-size: 18px;
      font-weight: 700;
      margin-top: 35px;
      margin-bottom: 20px;
    }
    .box-summary {
      margin-bottom: 35px;
      .inpt-group {
        margin-bottom: 10px !important;
        --bs-gutter-x: 0px !important;
        label {
          padding: 0px;
        }
        div {
          input {
            padding: 0px;
            border: 0px;
            background-color: transparent !important;
          }
        }
      }
    }
    .braintree-heading {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .pay-now {
    margin-top: 35px;
    .pay-now-title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
  .bank-transfer {
    margin-top: 35px;
  }
}
