//
// Nav
//

// Line tabs
.even.nav-tabs {
  display: flex;
  justify-content: space-around;
  .nav-item {
    flex: 1;
    cursor: pointer;
    text-align: center;
  }
  .nav-link {
    font-weight: 700;
    font-size: 14px;
    color: var(--gf-neutral-400) !important;
    border: 0;
    border-bottom: 4px solid var(--gf-neutral-200);
    min-height: 48px;
    padding: initial;
  }
  .nav-link.active,
  .nav-link:hover:not(.disabled) {
    color: var(--gf-primary-300) !important;
  }
  .nav-link.active {
    font-size: 18px;
    border: 0;
    min-height: 48px;
    padding: initial;
    border-bottom: 4px solid var(--gf-primary-300);
  }
}
.uneven.nav-tabs {
  .nav-item {
    cursor: pointer;
  }
  .nav-link {
    font-weight: 700;
    font-size: 16px;
    color: var(--gf-neutral-400) !important;
    border: 0;
    border-bottom: 2px solid var(--gf-neutral-200);
    min-height: 56px;
    padding: 16px 20px !important;
    line-height: 125%;
    letter-spacing: 0.6px;
  }
  .nav-link.active,
  .nav-link:hover:not(.disabled) {
    color: var(--gf-primary-300) !important;
    // border-bottom: 4px solid var(--gf-primary-300);
  }
  .nav-link.active {
    font-size: 16px;
    border: 0;
    min-height: 56px;
    // padding: inherit;
    // padding: 5px 30px 12px 30px !important;
    line-height: 125%;
    border-bottom: 2px solid var(--gf-primary-300);
  }
  &.no-span {
    .nav-link {
      min-height: inherit;
      &.active {
        min-height: inherit;
      }
    }
  }
}
.nav-tabs {
  .nav-item {
    cursor: pointer;
  }
  .nav-link {
    font-weight: 700;
    font-size: 16px;
    color: var(--gf-neutral-400) !important;
  }
  .nav-link.active,
  .nav-link:hover:not(.disabled) {
    color: var(--gf-primary-300) !important;
  }
  .nav-link.active {
    font-size: 18px;
  }
}
.nav-line-tabs {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $border-color;

  .nav-item {
    margin-bottom: -1px;

    // Base link
    .nav-link {
      //   color: $gray-500;
      color: var(--gf-neutral-300) !important;
      border: 0;
      border-bottom: 1px solid transparent;
      transition: $transition-link;
      padding: 0.5rem 0;
      margin: 0 1rem;
      font-weight: 700;
    }

    // First Item
    &:first-child {
      .nav-link {
        margin-left: 0;
      }
    }

    // Last Item
    &:last-child {
      .nav-link {
        margin-right: 0;
      }
    }
  }

  // Active & Hover States
  .nav-item .nav-link.active,
  .nav-item.show .nav-link,
  .nav-item .nav-link:hover:not(.disabled) {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $primary;
    transition: $transition-link;
    // color: $gray-500;
    color: var(--gf-primary-300) !important;
  }

  // 2x Line
  &.nav-line-tabs-2x {
    border-bottom-width: 2px;

    .nav-item {
      margin-bottom: -2px;

      .nav-link {
        border-bottom-width: 2px;
      }
    }

    // Active & Hover States
    .nav-item .nav-link.active,
    .nav-item.show .nav-link,
    .nav-item .nav-link:hover:not(.disabled) {
      border-bottom-width: 2px;
    }
  }
}

// Nav Pills
.nav-pills {
  .nav-item {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

// Stretch items
.nav-stretch {
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .nav-item {
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .nav-link {
    display: flex;
    align-items: center;
  }
}

// Nav group
.nav-group {
  padding: 0.35rem;
  @include border-radius($border-radius);
  background-color: $gray-100;

  // Outline nav group
  &.nav-group-outline {
    background-color: transparent;
    border: 1px solid $border-color;
  }

  // Fluid option
  &.nav-group-fluid {
    display: flex;

    > label,
    > .btn {
      position: relative;
      flex-shrink: 0;
      flex-grow: 1;
      flex-basis: 0;
    }

    > label {
      margin-right: 0.1rem;

      > .btn {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
