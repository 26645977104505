.box-create-opsi {
  border-radius: 18px;
  border: 1px solid #e6f4f2;
  padding: 24px;
  text-align: center;
  flex: 1;
  img,
  p {
    margin-bottom: 24px;
  }
}
