.modal-mobile-warning {
  .modal-header {
    border-bottom: none;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    width: 170px;
    margin: 0px auto 20px;
  }
  .text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .btn-ok {
    height: 45px;
    width: 150px;
    margin-bottom: 40px;
    border-radius: 30px;
  }
}
