.subtitle {
  @include font-18-bold;
  color: var(--gf-dark-violet-300);
  line-height: 20px;
  margin-bottom: 25px;
}

.text-underline {
  text-decoration: underline !important;
  text-underline-offset: 5px;
}

.restricted {
  position: relative;
  &:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    opacity: 0.9;
    filter: blur(10px);
    z-index: 3;
  }
}

.menu-disabled .menu-title,
.menu-disabled {
  color: var(--gf-neutral-400) !important;
}
