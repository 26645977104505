//
// Custom utilities
//

$utilities: map-merge(
  $utilities,
  (
    'position': (
      property: position,
      responsive: true,
      values: static relative absolute fixed sticky,
    ),
    'opacity': (
      property: opacity,
      class: opacity,
      values: $opacity-values,
    ),
    'opacity-hover': (
      property: opacity,
      state: hover,
      class: opacity,
      values: $opacity-values,
    ),
    'font-size': (
      rfs: true,
      responsive: true,
      property: font-size,
      class: fs,
      values: $font-sizes,
    ),
    'width': (
      responsive: true,
      property: width,
      class: w,
      values: $custom-sizes,
    ),
    'max-width': (
      responsive: true,
      property: max-width,
      class: mw,
      values: $custom-sizes,
    ),
    'min-width': (
      responsive: true,
      property: min-width,
      class: min-w,
      values: $custom-sizes,
    ),
    'height': (
      responsive: true,
      property: height,
      class: h,
      values: $custom-sizes,
    ),
    'max-height': (
      responsive: true,
      property: max-height,
      class: mh,
      values: $custom-sizes,
    ),
    'min-height': (
      responsive: true,
      property: min-height,
      class: min-h,
      values: $custom-sizes,
    ),
    'z-index': (
      property: z-index,
      class: z-index,
      values: $zindex-values,
    ),
    'border-top-width': (
      property: border-top-width,
      class: border-top,
      values: $border-widths,
    ),
    'border-bottom-width': (
      property: border-bottom-width,
      class: border-bottom,
      values: $border-widths,
    ),
    'border-right-width': (
      property: border-right-width,
      class: border-right,
      values: $border-widths,
    ),
    'border-left-width': (
      property: border-left-width,
      class: border-left,
      values: $border-widths,
    ),
    'line-height': (
      property: line-height,
      class: lh,
      values: (
        0: 0,
        1: 1,
        sm: $line-height-sm,
        base: $line-height-base,
        lg: $line-height-lg,
        xl: $line-height-xl,
        xxl: $line-height-xxl,
      ),
    ),
    'letter-spacing': (
      property: letter-spacing,
      class: ls,
      values: $letter-spacing-values,
    ),
  )
);

.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}
.w-50 {
  width: 50%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10%;
}
